<template>
  <div class="the-menu">
    <v-menu>
      <template v-slot:activator="{ props }">
        <v-btn variant="text" icon v-bind="props" class="menu-btn">
          <img width="25" src="/images/header/menu-icon.svg" />
        </v-btn>
      </template>
      <v-list class="item-list">
        <img class="close-btn" src="/images/header/close-icon.svg" />
        <v-list class="d-flex mt-10">
          <v-avatar v-if="authStore.auth.isLoggedIn" class="mx-2">
            <v-img
              :src="authStore.auth.user.avatar"
              alt="User Avatar"
            ></v-img>
          </v-avatar>
          <h2 v-if="authStore.auth.isLoggedIn && authStore.fullName">{{ authStore.fullName }}</h2>
        </v-list>
        <v-list v-if="authStore.auth.isLoggedIn">
          <div class="ma-4">
            <div class="my-2">
              <NuxtLink to="/profile">
                <span class="text-dark">حسابي</span>
              </NuxtLink>
            </div>
            <div class="my-4">
              <NuxtLink to="/sessions">
                <span class="text-dark">جلساتي</span>
              </NuxtLink>
            </div>
            <div class="my-2">
              <NuxtLink to="/wallet">
                <span class="text-dark">المحفظة</span>
              </NuxtLink>
            </div>
            <div class="my-2">
              <NuxtLink to="/orders">
                <span class="text-dark">الطلبات</span>
              </NuxtLink>
            </div>
            <div class="my-2">
              <hr class="mt-8 mb-n4" style="background: #d6d6d6" />
            </div>
          </div>
        </v-list>
        <v-list v-else>
          <div class="mt-15 mx-4">
            <NuxtLink to="/coach/details">
              <v-btn  class="font-weight-bold btn-primary rounded-lg">
                ابدأ اﻵن </v-btn
              >
            </NuxtLink>
            <v-btn
              variant="outlined"
              color="black"
              class="rounded-lg mr-1 font-weight-bold"
              @click="navigateTo('/auth/signin')"
            >
              دخول / تسجيل</v-btn
            >
            <div class="my-2 ma-4">
              <hr class="mt-8 mb-n4" style="background: #d6d6d6" />
            </div>
          </div>
        </v-list>
        <v-list>
          <v-list-item
            v-for="(item, index) in pagesList"
            :key="index"
            :value="index"
          >
            <v-list-item-title>
              <NuxtLink :to="handleRoute(item)" 
              @click="handleScrollPosition(item)" :target="item.title == 'انضم كمرشد' ? 'blank' : ''"
              class="font-weight-bold">{{
                item.title
              }}</NuxtLink></v-list-item-title
            >
          </v-list-item>

          <v-btn
            v-if="authStore.auth.isLoggedIn"
            class="logout-btn mx-4 mt-10"
            @click="authStore.signOut"
          >
            <span class="text-sm mb-2">تسجيل الخروج</span>
            <template v-slot:append>
              <v-avatar>
                <v-img
                  src="images/header/logout.svg"
                  width="20"
                  height="20"
                ></v-img>
              </v-avatar>
            </template>
          </v-btn>
        </v-list>
      </v-list>
    </v-menu>
  </div>
</template>
<script setup>
import { useAuthStore } from '~~/stores/AuthStore';

const authStore = useAuthStore();

const route = useRoute()

const runtimeConfig = useRuntimeConfig();

const pagesList = reactive([
  {
    title: 'الرئيسية',
    link: '/',
    static: true,
  },
  {
    title: 'كيف أبدأ',
    link: '#how-to-start',
  },
  {
    title: 'قائمة المرشدين',
    link: '/coach/details',
  },
  {
    title: 'انضم كمرشد',
    link: `${authStore.coachUrl}`,
  },
  {
    title: 'تواصل معنا',
    link: '/contact-us',
  },
]);


function scrollTo(value) {
  setTimeout(() => {
    window.scrollTo({
      top: value, 
      behavior: 'smooth'
    });
  }, 100);
}

function handleRoute(item) {
  if(route.name === 'index') return item.link 
  else if(item.link === '#coaches' || route.name === '/coach/details') {
    scrollTo('0') 
    return '/coach/details' 
  } 
    else return '/'
}

function handleScrollPosition(item) {

  if(item.link === '#coaches' && route.name === 'index') 
    scrollTo('900')  
  else if(item.link === '#how-to-start') 
    scrollTo('2300') 
  else '/coach/details'
}
</script>

<style >
.item-list {
  width: 300px;
  right: -20px;
  top: -17px;
  height: 150vh !important;
}

.close-btn {
  position: fixed;
  right: 89%;
  top: -1px;
  z-index: 1;
}

.the-menu{
  display: none;
}

@media (max-width: 1100px) {
  .the-menu{
    display: inline;
  }
}
</style>
